html {
  box-sizing: border-box;
}

body {
  padding-bottom: 24px;
}

*, *:before, *:after {
  box-sizing: inherit;
}


h1, h2, h3, p {
  margin: 0;
  color: #222222;
}

h2 {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 22px;
  text-transform: uppercase;
}

h3 {
  margin-bottom: 8px;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a, a:visited {
  color: #237a9a;
  text-decoration: none;
}

.bold {
  font-weight: 600;
}

.AppHeader {
  padding: 24px 0;
  color: white;
  display: flex;
  flex-direction: row;
  background-image: url('./background.jpeg');
  background-position: center;
  background-size: cover;
  height: 85vh;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.AppHeader__shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.4;
  z-index: 0;
}

.AppHeader__content {
  text-align: center;
  position: relative;
  z-index: 5;
}

.AppHeader__content h1, .AppHeader__content p, .AppHeader__content a {
  color: white;
}

.AppHeader h1 {
  font-weight: 200;
  letter-spacing: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 64px;
  margin-bottom: 8px;
}

.AppHeader p {
  margin-bottom: 2px;
}

.AppNav {
  display: flex;
  align-self: flex-end;
}

.container {
  max-width: 1024px;
  margin: 0 auto;
}

.Time {
  color:#808080;
  font-size: 12px;
  margin-bottom: 5px;
  display: inline-block;
}

.Signature {
  margin-top: 30px;
}

.Hero__image {
  max-width: 100%;
  height: auto;
}

.PartnersList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PartnersList div:not(:last-child) {
  margin-right: 50px;
}

.PartnersList img {
  width: 100%;
  max-width: 200px;
  height: auto;
}

.PartnersList .PartnersList__oway {
  max-width: 65px;
}

.Latest p {
  margin-bottom: 8px;
}

.Latest__post {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
}

.Latest__image {
  width: 100%;
  display: block;
  height: 200px;
  object-fit: cover;
  margin-top: 5px;
  margin-bottom: 10px;
}

.Latest__image--corona {
  width: 100%;
  height: auto;
  display: block;
  border: 1px solid #7d7d7d;
  margin: 5px 0 10px 0;
}

.Latest .Latest__post:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.TextList {
  margin: 0;
  padding: 0;
}

.TextList__item {
  margin-bottom: 4px;
}

.SecondaryContent__section {
  margin-bottom: 24px;
}

.Content {
  display: flex;
  margin-bottom: 48px;
  margin-top: 48px;
}

.MainContent {
  flex-basis: 50%;
}

.SecondaryContent {
  flex-basis: 100%;
  display: flex;
  justify-content: space-between;
}

.Footer {
  margin-top: 96px;
  text-align: center;;
  font-size: 14px;
}

.Footer p {
  color: #afadad;
}

@media (max-width: 1044px) {
  .container {
    margin: 0 16px;
  }
}

@media (max-width: 800px) {
  .MainContent, .SecondaryContent {
    flex-basis: 100%;
  }

  .SecondaryContent {
    display: flex;
    flex-direction: column;
  }

  .Hero, .Content {
    margin-bottom: 36px;
  }

  .SecondaryContent__section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .SecondaryContent .SecondaryContent__section:last-child {
    margin-top: 36px;
  }

  .Content {
    flex-direction: column;
  }

  .Footer {
    margin-bottom: 36px;
  }

  .AppHeader {
    height: 60vh;
  }

  .AppHeader h1 {
    font-size: 42px;    
  }
}

.BulletList {
  list-style: disc;
  padding-left: 1em;
}

.BulletList li:not(:last-child) {
  margin-bottom: 8px;
}

.BulletList--emph {
  color: red;
}

.Emph {
  font-weight: bold;
  text-decoration: underline;
  text-transform: uppercase;
}

.Corona {
  margin: 20px 0;
}